import { Link, } from "react-router-dom"

function Image({src}) {
    return (
        <img src={src} alt="" />
    )
}
function Text({children}) {
    return <p>{children}</p>
}
function Info({children}) {
    return <h1 className="title-text">{children}</h1>
}

function CTA({children, href}) {
    return <div><Link to={href}>{children}</Link></div>
}

export {
    Image,
    Text,
    Info,
    CTA,
}