import { Image as TIImage, Text as TIText, Info as TIInfo, CTA as TICTA } from "../components/general/quoteImage";
import { ImageLink } from "../components/general/imageLinks";
import { SectionTitle } from "../components/general/general";

function TextImage({children, name, src, CTA, CTAlink}){
    return (
        <div className="quote-image">
            <TIImage src={src} />
            <TIText>{children}</TIText>
            { CTAlink? <TICTA href={CTAlink}>{CTA}</TICTA> : null }
            <TIInfo>{name}</TIInfo>
        </div>
    )
}

function ImageLinks({children}) {
    return <div className="image-links">
        <div>
            {children.map((e,i)=>
                <ImageLink src={e.thumbnail} target={e.id} key={i}>{e.shortTitle}</ImageLink>
            )}
        </div>
    </div>
}

export {
    TextImage,
    ImageLinks,
    SectionTitle,
}