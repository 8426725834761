import { SectionTitle, Button } from "../components/general/general";
import { InitiativeThumbnail, Effect } from "../components/benefits/benefits";

export default function BenefitSection({children, altTitle, hideButton}){
    return <>
    <SectionTitle id={children.id}>{altTitle?altTitle:children.title}</SectionTitle>
    <div className="bene-section section">
        <div className="bene-box container">
            <div>
                <img src={children.beneImg} alt="" />
                <div className="effects-box">
                    {children.effects.map((e,i)=><Effect key={i}>{e}</Effect>)}
                </div>
            </div>
            {children.initiatives.length > 0 ? <div className="initiatives-box">
                <h3>Relevant Initiatives:</h3>
                <div>
                    {children.initiatives.map((e, i)=><InitiativeThumbnail key={i}>{e.title}</InitiativeThumbnail>)}
                </div>
            </div> : null}
            {hideButton? null : <Button to={children.path}>Read More</Button>}
        </div>
    </div>
    </>
}