function Text({children}) {
    return <p>{children}</p>
}

function Image({src}) {
    return <img src={src} alt="" className="tech-info-img" />
}

export {
    Text,
    Image,
}