import Mountain from "../images/landing/FILLER_IMAGE_HEATHER_LAKE.jpg";
import filler from "../images/general/Filler.jpg";
import filler_wide from "../images/general/Filler_wide.jpg";
import { TextImage } from "../sections/general";
import { InitiativeSection, TagSection } from "../sections/initiatives";
import { initiatives, tags } from "../dataFiles/initiatives";
import "../css/initiatives.css"

export default function Initiatives() {
    console.log(initiatives)
    return <div className="App">
        <TextImage name="GHP" src={Mountain}>Initiatives</TextImage>
        <TagSection>{tags}</TagSection>
        <InitiativeSection>{initiatives}</InitiativeSection>
    </div>
}