import filler from "../images/general/Filler.jpg";

var stories = [
    {
        "title" : "Sample Title",
        "date" : "March 15th",
        "publication" : "The Onion",
        "link" : "https://www.wsj.com/us-news/law/trumps-355-million-headache-what-comes-next-in-civil-fraud-case-a069bf09",
        "imgSrc" : filler,
        "tags": ["Example", "filler"]
    },
    {
        "title" : "Sample Title",
        "date" : "March 15th",
        "publication" : "The Onion",
        "link" : "https://www.wsj.com/us-news/law/trumps-355-million-headache-what-comes-next-in-civil-fraud-case-a069bf09",
        "imgSrc" : filler,
        "tags": ["Example", "filler"]
    },
    {
        "title" : "Sample Title",
        "date" : "March 15th",
        "publication" : "The Onion",
        "link" : "https://www.wsj.com/us-news/law/trumps-355-million-headache-what-comes-next-in-civil-fraud-case-a069bf09",
        "imgSrc" : filler,
        "tags": ["Example", "filler"]
    },
    {
        "title" : "Sample Title",
        "date" : "March 15th",
        "publication" : "The Onion",
        "link" : "https://www.wsj.com/us-news/law/trumps-355-million-headache-what-comes-next-in-civil-fraud-case-a069bf09",
        "imgSrc" : filler,
        "tags": ["Example", "filler"]
    },
    {
        "title" : "Sample Title",
        "date" : "March 15th",
        "publication" : "The Onion",
        "link" : "https://www.wsj.com/us-news/law/trumps-355-million-headache-what-comes-next-in-civil-fraud-case-a069bf09",
        "imgSrc" : filler,
        "tags": ["Example", "filler"]
    },
    {
        "title" : "Sample Title",
        "date" : "March 15th",
        "publication" : "The Onion",
        "link" : "https://www.wsj.com/us-news/law/trumps-355-million-headache-what-comes-next-in-civil-fraud-case-a069bf09",
        "imgSrc" : filler,
        "tags": ["Example", "filler"]
    },
    {
        "title" : "Sample Title",
        "date" : "March 15th",
        "publication" : "The Onion",
        "link" : "https://www.wsj.com/us-news/law/trumps-355-million-headache-what-comes-next-in-civil-fraud-case-a069bf09",
        "imgSrc" : filler,
        "tags": ["Example", "filler"]
    },
    {
        "title" : "Sample Title",
        "date" : "March 15th",
        "publication" : "The Onion",
        "link" : "https://www.wsj.com/us-news/law/trumps-355-million-headache-what-comes-next-in-civil-fraud-case-a069bf09",
        "imgSrc" : filler,
        "tags": ["Example", "filler"]
    },
    
]

export {
    stories,
}
