import { Image as TCImage, Text as TCText, Title as TCTitle } from "../components/landing/tripleCard";
import { Title as SaTitle, CenterLine as SaLine, Text as SaText, Bullets as SaBullets } from "../components/landing/Savings";
import { Card as PrCard } from "../components/landing/PracticesCard"
import { Card as PoSoCard } from "../components/landing/PowerSourcesCard"
import { NewsBox } from "../components/news/news";

function TripleCard({text}){
    function Card({title, src, text, href}){
        return (
            <div>
                <TCImage src={src} />
                <TCTitle>{title}</TCTitle>
                <TCText>{text}</TCText>
            </div>
        )
    }

    return (
        <div className="triple-card">
            {text.map((a,i)=><Card title={a.title} src={a.src} text={a.desc} key={i} />)}
        </div>
    )
}

function SavingsSlide({leftTitle, leftDesc, leftBullets, rightTitle, rightDesc, rightBullets}) {
    return (
        <div className="savings-slide section">
            <div className="split-container container">
                <div className="left-col">
                    <SaTitle>{leftTitle}</SaTitle>
                    <SaText>{leftDesc}</SaText>
                    {leftBullets ? <SaBullets>{leftBullets}</SaBullets> : null }
                </div>
                <SaLine />
                <div className="right-col">
                    <SaTitle>{rightTitle}</SaTitle>
                    <SaText>{rightDesc}</SaText>
                    {leftBullets ? <SaBullets>{rightBullets}</SaBullets> : null }
                </div>
            </div>
        </div>
    );
}

function PracticesSlide({practices}) { // [{"src":<src>, "desc":<desc>, "href":<href>}, ...]
    let cards = practices.map((e, i)=><PrCard key={i} src={e["src"]} href={e["href"]}>{e["desc"]}</PrCard>)
    return (
        <div className="practices-slide">
            <div className="practices-container container">
                {cards}
            </div>
        </div>
    )
}

function PowerSourcesSlide({children}) {
    return (
        <div className="power-sources-slide section">
            <div className="power-sources-container container">
                { children.map((e,i)=><PoSoCard key={i} src={e.thumbnail} text={e.shortTitle} href={e.path} />) }
            </div>
        </div>
    );
}

function UpdatesSlide({children}) {
    return (
        <div className="updates-slide section">
            <div className="updates-container container">
                {children.slice(0,4).map((e,i)=><NewsBox key={i}>{e}</NewsBox>)}
            </div>
        </div>
    )
}

function ImpactSlide({children}) {
    return <div className="impact-slide section">
        <div className="impact-container container">
            <p>{children}</p>
        </div>
    </div>
}

export {
    TripleCard,
    ImpactSlide,
    SavingsSlide,
    PracticesSlide,
    PowerSourcesSlide,
    UpdatesSlide,
}