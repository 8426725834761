import BenefitSection from "../sections/benefits";
import InfoSection from "../sections/moreInfo";
import Mountain from "../images/landing/FILLER_IMAGE_HEATHER_LAKE.jpg";
import filler from "../images/general/Filler.jpg";
import filler_wide from "../images/general/Filler_wide.jpg";
import { TextImage } from "../sections/general";

export default function Technology({children}) {
    return <div className="App">
        <TextImage name="GHP" src={Mountain}>{children.title}</TextImage>
        <BenefitSection hideButton={true} altTitle={"Benefits"}>{children}</BenefitSection>
        <InfoSection hideButton={true} altTitle={"More Information"}>{children}</InfoSection>
    </div>
}