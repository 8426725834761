import { Button } from "../general/general"

function toId(s) {
    return `init-tag-${s}`
}

function Initiative({children}) {
    return <div className={`initiative-box active ${children.tags.map(e=>toId(e)).join(" ")}`}>
        <h2>{children.title}</h2>
        <p>{children.desc}</p>
        {children.link?<Button to={children.link}>Learn More</Button>:null}
    </div>
}

function TagCard({select, children}) {
    return <button className="tag-box" onClick={e=>select(toId(children), e)}>{children}</button>
}

export {
    Initiative,
    TagCard,
}