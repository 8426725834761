function Title({children}){
    return <h1>{children}</h1>
}

function Text({children}) {
    return <p>{children}</p>
}

function Bullets({children}){
    let points = children.map((child, i)=><li key={i}>{child}</li>)
    return <ul>{points}</ul>
}

function CenterLine(){
    return <span></span>
}

export {
    Title,
    CenterLine,
    Text,
    Bullets,
}