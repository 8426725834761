import { SectionTitle } from "../sections/general"
import { TextImage } from "../sections/general"
import { JustTextSlide } from "../sections/about"
import Mountain from "../images/landing/FILLER_IMAGE_HEATHER_LAKE.jpg"
import "../css/about.css"

export default function About(){
    return (
        <div className="App">
            <TextImage name="GHP" src={Mountain}>About</TextImage>
            <SectionTitle>Who We Are</SectionTitle>
            <JustTextSlide text={["Hello, we are the Green Homes Project. We are a clean energy non-profit and we want to help you help the planet. Since our founding in 2007 by former Secretary of Energy Mary Rogers, we have worked tirelessly to bring you the latest innovations in clean energy as well as making it as affordable as possible. Over our 17 years of service, we have brought clean energy and energy-efficient systems to over 120,000 homes and have reduced carbon emissions by 480,000 tons annually through education and advocacy. We hope that through us you find something that suits both you and the environment to improve everyone's life."]} />
            <SectionTitle>Our Mission</SectionTitle>
            <JustTextSlide text={["Our mission is to make every household in America carbon neutral to improve both the planet and your quality of life. With our outreach and advocacy, we aim to educate everyone on the benefits and feasibility of clean energy and efficient systems. We want to show the world that a brighter, cleaner future is possible."]} />
        </div>
    )
}