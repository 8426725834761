import Filler from "../images/general/Filler.jpg";
import { initiatives } from "./initiatives";
import Wide_Filler from "../images/general/Filler_wide.jpg";
import Technology from "../pages/Technology";

import panelThumbnail from "../images/technology/panels-1.jpg";
import panelBenefits from "../images/technology/panels-2.jpg";
import panelInfo from "../images/technology/panels-3.jpg";

import geothermalWide from "../images/benefits/viktor-kiryanov-lAcYPEiau0U-unsplash.jpg"
import geothermalThumb from "../images/technology/tommy-kwak-t6u6EPbWNjw-unsplash.jpg"
import geothermalInfo from "../images/technology/jason-blackeye-zAITDJYV09w-unsplash.jpg"

import windWide from "../images/landing/peter-beukema-OovEfuIwBOM-unsplash.jpg"
import windThumb from "../images/technology/bruno-figueiredo-KAXSflHqAl0-unsplash.jpg"
import windInfo from "../images/technology/luo-lei-Xa4pTwafe5U-unsplash.jpg"

import nuclearInfo from "../images/technology/nicolas-hippert-C82jAEQkfE0-unsplash.jpg"
import nuclearWide from "../images/technology/lukas-lehotsky-Xhle0cfnKrg-unsplash.jpg"
import nuclearThumb from "../images/technology/lukas-lehotsky-ixcHGhae2mg-unsplash.jpg"

var technology = [
    {
        "title" : "Solar Energy",
        "thumbnail": panelThumbnail,
        "shortTitle" : "Solar",
        "id" : "solar",
        "path" : "solar",
        "infoImg" : panelInfo,
        "desc" : "The average US household consumes approximately 890 kilowatt-hours of electricity per month, contributing to significant energy bills. However, by integrating solar power installations into homes, households can dramatically reduce their reliance on traditional grid electricity. On average, solar panels generate up to 850 kilowatt-hours of electricity per month, depending on factors such as location, sunlight exposure, and system efficiency. This substantial generation capacity empowers homeowners to offset a significant portion of their monthly energy consumption with clean, renewable energy. The financial benefits of installing solar panels extend beyond monthly energy savings. With solar power, homeowners can reduce their energy bills by more than 95%, resulting in substantial long-term savings. The average US household spends approximately $2,400 annually on energy bills, a significant portion of which can be eliminated through solar energy generation. Over the 25-30 year lifespan of a solar system, it's estimated that homeowners could save between $25,000 and $33,000, assuming the cost of energy remains constant. However, even if energy prices rise over time, the savings from solar power installations would only increase, further enhancing the financial benefits for homeowners. Although solar panels do have a high initial cost for installation, in the long run they are very beneficial to both the environment and your wallet.",
        "initiatives" : [0],
        "beneImg" : panelBenefits,
        "effects" : [
            "$15,000 avg. home value increase",
            "Save on energy at home"
        ]
    },
    {
        "title" : "Geothermal Energy",
        "thumbnail": geothermalThumb,
        "shortTitle" : "Geothermal",
        "id" : "geo",
        "path" : "geothermal",
        "infoImg" : geothermalInfo,
        "desc" : "At-home installations of geothermal energy offer a highly efficient and environmentally friendly alternative for residential heating, cooling, and hot water production. Geothermal systems harness the stable temperature of the earth beneath the surface, providing a reliable source of energy year-round. According to the U.S. Environmental Protection Agency (EPA), geothermal heat pumps can reduce energy consumption for heating by 20% to 50% compared to traditional heating systems, and by 30% to 60% for cooling. Additionally, geothermal energy produces minimal greenhouse gas emissions, contributing to efforts to mitigate climate change. The EPA estimates that the widespread adoption of geothermal heat pumps in the United States could reduce carbon dioxide emissions by 17.4 million metric tons annually, equivalent to removing 3.2 million cars from the road. Furthermore, geothermal systems have long lifespans, with components lasting up to 25 years or more, reducing maintenance costs and providing reliable energy for decades. By investing in geothermal energy for residential use, homeowners can not only lower their energy bills but also contribute to environmental sustainability and reduce their carbon footprint.",
        "initiatives" : [],
        "beneImg" : geothermalWide,
        "effects" : [
            "Can be reliably harvested almost everywhere",
            "Reliable power"
        ]
    },
    {
        "title" : "Wind Energy",
        "thumbnail" : windThumb,
        "shortTitle" : "Wind",
        "id" : "wind",
        "path" : "wind",
        "infoImg" : windInfo,
        "desc" : "At-home residential wind turbines present a promising avenue for sustainable energy generation, offering homeowners an opportunity to harness clean and renewable wind power on their property. Studies show that residential wind turbines have the potential to significantly offset household electricity consumption. According to the U.S. Department of Energy, a small wind turbine with a capacity of 10 kilowatts can produce 10,000 to 20,000 kilowatt-hours of electricity annually, which is enough to meet the needs of an average household. Furthermore, residential wind turbines can lead to substantial cost savings over their operational lifespan. The American Wind Energy Association (AWEA) estimates that a residential wind turbine system can pay for itself within 6 to 30 years, depending on factors such as wind resource availability and local electricity rates. Additionally, by generating electricity from wind power, homeowners can reduce their reliance on fossil fuels, lowering carbon emissions and contributing to climate change mitigation efforts. With advancements in technology making wind turbines more efficient and affordable, residential wind energy systems present a great option for reducing carbon emissions and reducing your reliance on the grid.",
        "initiatives" : [0],
        "beneImg" : Wide_Filler,
        "beneImg" : windWide,
        "effects" : [
            "336 million metric tons of CO2 avoided per year",
            "Operates in all 50 states"
        ]
    },
    {
        "title" : "Nuclear Energy",
        "thumbnail" : nuclearThumb,
        "shortTitle" : "Nuclear",
        "id" : "nuclear",
        "path" : "nuclear",
        "infoImg" : nuclearInfo,
        "desc" : "Nuclear energy is an amazing source of clean energy moving forward. Nuclear reactors are incredibly efficient and are very safe to operate, especially newer reactors. Since 1990 nuclear reactors have supplied roughly 20% of America’s energy and nuclear energy has the highest capacity factor at 92.5% whereas coal plants are only at 40.2%. A single nuclear power plant also produces an entire gigawatt of energy every year. To put that into perspective a gigawatt of energy is equal to the amount produced each year by 3.125 million PV solar panels or 431 utility-scale wind turbines. However, it gets even more impressive due to the reactor's high capacity factor. You would need two gigawatt-scale coal plants to produce as much energy as a single gigawatt-scale nuclear reactor or three to four clean energy plants of one-gigawatt size to produce that much energy. Beyond the efficiency of nuclear reactors, they are also very safe and clean. Reactors are estimated to have a failure probability of 1 chance in 1,000,000,000. Nuclear power does produce nuclear waste but that isn’t even that big of a deal. The entire U.S. produces 2,000 metric tons of spent fuel which is less than half of the volume of an Olympic swimming pool. Furthermore, due to Orano’s developments in working with nuclear waste, we are able to recycle 96% of spent fuel to produce more energy. The amount of actual unusable waste produced is incredibly small and doesn’t pose much of a problem.",
        "initiatives" : [],
        "beneImg" : nuclearWide,
        "effects" : [
            "Contributes $60 billion to US GDP annually",
            "Largest clean power source"
        ]
    }
]

function techPage(info) {
    return function routerHandler(){
        return <Technology>{info}</Technology>
    }
}

technology.forEach(e=>{
    e["techPage"] = techPage(e);
    e["path"] = `/technology/${e["path"]}`;
    e["initiatives"] = e["initiatives"].map(e=>initiatives[e])
    console.log(e["initiatives"])
})

export {
    technology,
}