var tags = {
    0 : "Solar",
    1 : "Wind",
    2 : "Appliances",
    3 : "Vehicles"
}

var initiatives = {
    0 : {
        "title" : "30% of Cost as Tax credit",
        "tags" : [0, 1],
        "desc" : "Installing solar panels or a wind turnine will give you 30% of their cost as federal tax credits.",
        "link" : "https://www.energy.gov/policy/articles/making-our-homes-more-efficient-clean-energy-tax-credits-consumers"
    },
    1 : {
        "title" : "$2,000 Tax Credit",
        "tags" : [2],
        "desc" : "$2,000 tax credit for purchasing efficient heating and cooling equipment like a heat pump or central heating unit.",
        "link" : "https://www.energy.gov/policy/articles/making-our-homes-more-efficient-clean-energy-tax-credits-consumers"
    },
    2 : {
        "title" : "$150 tax Credit",
        "tags" : [],
        "desc" : "$150 tax credit for a home energy audit conducted by an inspector.",
        "link" : "https://www.energy.gov/policy/articles/making-our-homes-more-efficient-clean-energy-tax-credits-consumers"
    },
    3 : {
        "title" : "$4,000 rebate",
        "tags" : [2],
        "desc" : "Potential $4,000 rebate for electric appliances and home retrofits, for low income households that increases to up to $8,000.",
        "link" : "https://www.energy.gov/policy/articles/making-our-homes-more-efficient-clean-energy-tax-credits-consumers"
    },
    4 : {
        "title" : "Covering the Cost",
        "tags" : [2],
        "desc" : "Low and moderate income households can access rebates that cover up to 100% of the cost of installing electric appliances like a heat pump, water heater, or clothes dryer.",
        "link" : "https://www.energy.gov/policy/articles/making-our-homes-more-efficient-clean-energy-tax-credits-consumers"
    },
    5 : {
        "title" : "$7,500 Tax Credit",
        "tags" : [3],
        "desc" : "If buying an electric vehicle you can receive a tax credit of $7,500 and if you own an electric vehicle already you can get a tax credit of $4,000",
        "link" : "https://www.energy.gov/policy/articles/making-our-homes-more-efficient-clean-energy-tax-credits-consumers"
    }
}

Object.values(initiatives).forEach(e => {
    e["tags"] = e["tags"].map(e=>tags[e])
});

export {
    initiatives,
    tags,
}