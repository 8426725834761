import Mountain from "../images/landing/FILLER_IMAGE_HEATHER_LAKE.jpg";
import { TextImage, ImageLinks } from "../sections/general";
import filler from "../images/general/Filler.jpg";
import { technology } from "../dataFiles/technologies";
import InfoSection from "../sections/moreInfo"
import "../css/more-info.css"

export default function MoreInfo() {
    return (
        <div className="App">
            <TextImage name="GHP" src={Mountain}>More Information</TextImage>
            <ImageLinks>{technology}</ImageLinks>
            {technology.map((e, i) => <InfoSection key={i}>{e}</InfoSection>)}
        </div>
    ) 
}