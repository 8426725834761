import { LogoImg } from "../components/general/general"
import "../css/footer.css"
import { Link } from "react-router-dom";

export default function Footer() {
    return (<footer>
        <ul>
            <li className="footer-logo-area"><Link to="/"><LogoImg cls="footer-logo" /></Link></li>
            <li><Link to="/initiatives">Initiatives</Link></li>
            <li><Link to="/benefits">Benefits</Link></li>
            <li><Link to="/more-info">More Info</Link></li>
            <li><Link to="/tsa-info">TSA Info</Link></li>
        </ul>
        <h3 className="copyright-tag">Copyright 2024 - All Rights Reserved</h3>
    </footer>);
}