import { Initiative } from "../components/initiatives/initiatives"
import { TagCard } from "../components/initiatives/initiatives"


var selected = new Set()

function select(s, e) {
    if(selected.has(s)) {
        selected.delete(s)
        e.target.classList.remove("active")
    } else {
        selected.add(s)
        e.target.classList.add("active")
    }
    document.querySelectorAll(".initiative-box").forEach(e=>{
        if(selected.size === 0 || [...selected].some(c=>e.classList.contains(c))) {
            e.classList.add("active")
        } else {
            e.classList.remove("active")
        }
    })
}

function TagSection({children}) {
    return <div className="tag-slide section" >
        <div className="tag-container container">
            {Object.keys(children).map((e,i)=>
            <TagCard key={i} select={select} tagId={e} >{children[e]}</TagCard>)
            }
        </div>
    </div>
}

function InitiativeSection({children}) {
    return <div className="initiative-slide section">
        <div className="initiative-container container">
            {Object.values(children).map((e,i)=><Initiative key={i}>{e}</Initiative>)}
        </div>
    </div>
}

export {
    InitiativeSection,
    TagSection
}