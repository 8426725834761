import Mountain from "../images/landing/FILLER_IMAGE_HEATHER_LAKE.jpg";
import filler from "../images/general/Filler.jpg";
import filler_wide from "../images/general/Filler_wide.jpg";
import {
    TripleCard,
    SavingsSlide,
    PracticesSlide,
    PowerSourcesSlide,
    UpdatesSlide,
    ImpactSlide
} from "../sections/landing";
import {
    TextImage,
    SectionTitle
} from "../sections/general";
import { stories } from "../dataFiles/news";
import { technology } from "../dataFiles/technologies"
import "../css/landing.css";
import atHomeInstallations from "../images/landing/evy-prentice-rJe9rkOvBWY-unsplash.jpg"
import atHomeSolutions from "../images/landing/johannes-plenio-voQ97kezCx0-unsplash.jpg"
import supportableEnergies from "../images/landing/appolinary-kalashnikova-WYGhTLym344-unsplash.jpg"
import yourImpact from "../images/landing/noah-buscher-x8ZStukS2PM-unsplash.jpg"
import marsh from "../images/landing/brian-sumner--Uascf3aceQ-unsplash.jpg"
import bulbPractice from "../images/landing/absolutvision-82TpEld0_e4-unsplash.jpg"
import washingMachinePractice from "../images/landing/planetcare-23coWmkTNSg-unsplash.jpg"
import windowsPractice from "../images/landing/nathan-fertig-U8zsjmKA840-unsplash.jpg"
import acPractice from "../images/landing/dan-lefebvre-RFAHj4tI37Y-unsplash.jpg"

function Landing() {
  return (
    <div className="App">
        <TextImage CTA="Learn More" name="GHP" src={marsh}>Renew, Recharge, Reimagine: Create a cleaner planet for you.</TextImage>
        <SectionTitle>Getting Started</SectionTitle>
        <TripleCard text={[
            {
                "title" : "At home installations", 
                "desc" : "Take a look at our top performing at-home clean energy installations! We have geothermal heat pumps, solar energy systems, and wind energy systems.", 
                "src" : atHomeInstallations, 
            },
            {
                "title" : "At home solutions", 
                "desc" : "Some easy options for increasing the efficiency of your home are efficient windows and insulations, heating units, and rainwater collection systems.", 
                "src" : atHomeSolutions, 
            },
            {
                "title" : "Supportable energies", 
                "desc" : "Looking at big picture energy solutions, solar, hydropower, and nuclear are great options to help our country produce clean energy without fossil fuels.", 
                "src" : supportableEnergies, 
            },
        ]} />
        <SectionTitle>Your Impact</SectionTitle>
        <ImpactSlide>On average a household that installs solar panels reduces their annual carbon emissions by four tons. That is roughly twelve times less emissions than households that rely on the grid. Installing other clean energy producers, such as geothermal or wind power, can have similar effects on your carbon footprint. These installations are great for the environment and for you but they can be a tad expensive. Some alternative solutions are getting energy-efficient appliances. Energy-efficient windows, insulations, or heating units can reduce your energy consumption by up to 30%. Just producing or saving energy isn’t the only way to have a positive impact on the environment, water-saving fixtures or rainwater harvesting can reduce your consumption and reliance on government water by 50%. There are many options for ways to reduce your carbon footprint and help your planet and any little bit helps. Installing any of these appliances helps take a step towards a brighter, cleaner, future.</ImpactSlide>
        <SectionTitle>Savings</SectionTitle>
        <SavingsSlide 
          leftTitle="Green Energy"
          leftDesc="Clean energy offers numerous benefits over fossil fuels, primarily in terms of environmental sustainability and public health. Renewable energy sources such as solar and wind power produce little to no greenhouse gas emissions during operation, helping to mitigate climate change. According to the International Renewable Energy Agency (IRENA), renewable energy accounted for nearly 72% of all new power capacity additions globally in 2020. Furthermore, transitioning to clean energy can reduce air pollution, thereby improving public health outcomes. The American Lung Association reports that air pollution from burning fossil fuels contributes to respiratory illnesses, heart disease, and premature death. In contrast, renewable energy technologies like solar, wind, nuclear, and geothermal produce no air pollutants during operation, leading to cleaner air and healthier communities."
        //   leftBullets = {["lorem ipsum", "lorem ipsum", "lorem ipsum"]}
          rightTitle="Traditional Energy"
          rightDesc="Reliance on fossil fuels presents numerous drawbacks, primarily related to environmental degradation and human health impacts. Burning fossil fuels for electricity generation and transportation releases significant quantities of greenhouse gasses, contributing to climate change. The Intergovernmental Panel on Climate Change (IPCC) states that the burning of coal, oil, and natural gas accounts for approximately 75% of global greenhouse gas emissions. Additionally, fossil fuel extraction and processing activities often lead to environmental pollution, including water and soil contamination. The World Health Organization (WHO) estimates that air pollution from fossil fuel combustion is responsible for 7 million premature deaths worldwide each year. Moreover, the extraction and transportation of fossil fuels pose risks to worker safety and local communities, with incidents such as oil spills and pipeline leaks causing ecological disasters and threatening public health and livelihoods. Overall, the continued reliance on fossil fuels exacerbates environmental and health challenges, underscoring the urgent need for a transition to cleaner, renewable energy sources."
        //   rightBullets = {["lorem ipsum", "lorem ipsum"]}
        />
        <SectionTitle>Practices</SectionTitle>
        <PracticesSlide practices={[
            {"src":bulbPractice, "desc":"Install a small cost effective emission and pollution free home wind turbine. They can help lower your electricity bill by 50-90%, help provide power during outages, and get paid for excess energy during periods of high energy generation!"/*, "href":"/test"*/},
            {"src":washingMachinePractice, "desc":"Taking Advantage of the Sun: \nOpen curtains on the south-facing windows during the day to allow natural sunlight to heat up your home. \nClose your blinds and windows at night to reduce heat loss."},
            {"src":windowsPractice, "desc":"Reduce Heat Loss from Fireplace:\nKeep your fire damper closed unless a fire is burning. This will ensure that little heat will escape through your chimney.\nIf fireplace isn’t used often, plug and seal the chimney flue.\nAdd caulking around the fireplace hearth to help seal any openings heat can escape."},
            {"src":acPractice, "desc":"Lower Water Heating Costs:\nKeep temperature of water heater to 120 degrees fahrenheit to save energy as well as avoiding scalding!\nInsulate your water heater and pipes.\nUpdating your water heater to a more efficient model."},
        ]}/>
        <SectionTitle>Power Sources</SectionTitle>
        <PowerSourcesSlide>{technology}</PowerSourcesSlide>
    </div>
  );
}

export default Landing;
