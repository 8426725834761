function InitiativeThumbnail({children}){
    return <div className="initiative-thumbnail">{children}</div>
}

function Effect({children}) {
    return <p>{children}</p>
}

export {
    InitiativeThumbnail,
    Effect,
}