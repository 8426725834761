import { SectionTitle, Button } from "../components/general/general";
import { Text, Image } from "../components/moreInfo/moreInfo";

export default function InfoSection({children, altTitle, hideButton}) {
    return <>
    <SectionTitle id = {children.id}>{altTitle?altTitle:children.title}</SectionTitle>
    <div className="info-section section">
        <div className="info-box container">
            <Image src={children.infoImg}/>
            <div>
                <Text>{children.desc}</Text>
                {hideButton? null : <Button to={children.path}>Read More</Button>}
            </div>
            <br className="clear" />
        </div>
    </div>
    </>
}