import { DropDown, NavImg, NavLink } from "../components/navbar/navbar"
import { Link } from "react-router-dom"
import "../css/navbar.css"

export default function Navbar(){
    return(
        <>
        <div id="top"></div>
        <div className="navbox">
            <div>
                <div>
                    <Link className="logo-box title-text" to="/">
                        <NavImg/>
                    </Link>
                    <DropDown />
                    <div className="nav-links">
                        <NavLink href={"/initiatives"}>Initiatives</NavLink>
                        <NavLink href={"/benefits"}>Benefits</NavLink>
                        <NavLink href={"/more-info"}>More Info</NavLink>
                        <NavLink href={"/about"}>About</NavLink>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}