import React from 'react';
import ReactDOM from 'react-dom/client';

import './css/index.css';

import Navbar from './sections/navbar';
import Footer from './sections/footer';

import Landing from './pages/Landing';
import MoreInfo from './pages/MoreInfo';
import Initiatives from './pages/Initiatives';
import Benefits from './pages/Benefits';
import About from './pages/About';
import News from './pages/News';
import TSAInfo from './pages/TSAInfo';
import NoPage from './pages/NoPage';
import ScrollToTop from './ScrollToTop';
import { technology } from './dataFiles/technologies';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


export default function App(){
    return(
        <BrowserRouter>
            <ScrollToTop />
            <Navbar/>
            <Routes>
                <Route exact path="/" Component={Landing} />
                <Route exact path="/more-info" Component={MoreInfo} />
                <Route exact path="/initiatives" Component={Initiatives} />
                <Route exact path="/benefits" Component={Benefits} />
                <Route exact path="/about" Component={About} />
                <Route exact path="/news" Component={News} />
                <Route exact path="/tsa-info" Component={TSAInfo} />
                { technology.map((e,i)=><Route exact key={i} path={e.path} Component={e.techPage} />) }
                <Route path="*" element={<NoPage />} />
            </Routes>
            <Footer/>
        </BrowserRouter>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);