import { Button } from "../general/general";

function NewsBox({children}) {
    return <div className="news-box">
        <h3>{children.date}</h3>
        <div>
            <div>
                <h4>{children.publication}</h4>
                <h2>{children.title}</h2>
                <h4 className="tags">{children.tags.join(" • ")}</h4>
                <Button to={children.link} target="_blank">Read More</Button>
            </div>
            <img src={children.imgSrc} alt="" />
        </div>
    </div>
}

export {
    NewsBox,
}