function Image({src}){
    return <img src={src} alt="" />
}

function Title({children}){
    return <h2 className="title-text">{children}</h2>
}

function Text({children}){
    return <p>{children}</p>
}

export {
    Image,
    Title,
    Text,
}