import { Button } from "../general/general"

function Card({children, src, href}){
    return <div className="prac-card">
        <img src={src} alt="" />
        <p>{children}</p>
        {href ? <Button to={href}>Learn More</Button> : null}
        <br className="clear" />
    </div>
}

export {
    Card
}