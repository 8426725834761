import Mountain from "../images/landing/FILLER_IMAGE_HEATHER_LAKE.jpg";
import { TextImage, ImageLinks } from "../sections/general";
import filler from "../images/general/Filler.jpg";
import { technology } from "../dataFiles/technologies";
import BenefitSection from "../sections/benefits"

import "../css/benefits.css"

export default function Benefits(){
    return (
        <div className="App">
            <TextImage name="GHP" src={Mountain}>Benefits</TextImage>
            <ImageLinks>{technology}</ImageLinks>
            {technology.map((e, i) => <BenefitSection key={i}>{e}</BenefitSection>)}
        </div>
    )
}