function JustTextSlide({text}) {
    const paragraphs = text.map(paragraph => <p>{paragraph}</p>)
    return (<div className="just-text-slide">
        <div className="paragraphs">
            {paragraphs}
        </div>
    </div>)
}

export {
    JustTextSlide
}