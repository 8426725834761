import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faX } from '@fortawesome/free-solid-svg-icons'

import { LogoImg as NavImg } from "../general/general";

function NavLink({href, children}){
    return <Link className="nav-link" to={href}>{children}</Link>
}

function DropDown(){
    return <div className="dropdown-toggle" onClick={(e)=>e.target.classList.toggle("active")}>
        <FontAwesomeIcon icon={faBars} className="dd-bars" />
        <FontAwesomeIcon icon={faX} className="dd-x" />
    </div>
}

export {
    NavImg,
    NavLink,
    DropDown,
};