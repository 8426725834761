import { Link } from "react-router-dom"

function Card({src, text, href}){
    return <Link to={href} className="power-source-card">
        <p>{text}</p>
        <img src={src} alt="" />
    </Link>
}

export {
    Card
}