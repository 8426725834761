export default function TSAInfo(){
    return (
        <div>
            <h1>TSA Info</h1>
            <h2>Technologies Used</h2>
            <ul>
                <li>Kinsta for static site hosting</li>
                <li>Coded in ReactJS</li>
                <li>Vanilla CSS for styling; no CSS frameworks or component libraries used</li>
            </ul>
            <h2>Photo Credit</h2>
            <ul>
                <li>Photo by <a href="https://unsplash.com/@evy_prentice?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Evy Prentice</a> on <a href="https://unsplash.com/photos/white-front-load-washing-machine-rJe9rkOvBWY?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@jplenio?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Johannes Plenio</a> on <a href="https://unsplash.com/photos/close-up-photography-of-light-bulb-voQ97kezCx0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@appolinary_kalashnikova?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Appolinary Kalashnikova</a> on <a href="https://unsplash.com/photos/wind-turbine-surrounded-by-grass-WYGhTLym344?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@noahbuscher?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Noah Buscher</a> on <a href="https://unsplash.com/photos/green-plant-x8ZStukS2PM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@vki?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Viktor Kiryanov</a> on <a href="https://unsplash.com/photos/ship-on-body-of-water-at-night-lAcYPEiau0U?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@tommykwak?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Tommy Kwak</a> on <a href="https://unsplash.com/photos/red-and-white-barn-near-brown-field-under-white-clouds-during-daytime-t6u6EPbWNjw?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@jeisblack?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Jason Blackeye</a> on <a href="https://unsplash.com/photos/industrial-power-plant-skyline-zAITDJYV09w?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@bmsumner?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Brian Sumner</a> on <a href="https://unsplash.com/photos/winding-river-across-plains-under-downcast-ssky--Uascf3aceQ?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li></li>
                <li>Photo by <a href="https://unsplash.com/@planetcare?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">PlanetCare</a> on <a href="https://unsplash.com/photos/a-washer-and-dryer-in-a-room-23coWmkTNSg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@nathanfertig?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Nathan Fertig</a> on <a href="https://unsplash.com/photos/white-teapot-and-tow-flower-vases-on-windowpane-U8zsjmKA840?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@danlefeb?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Dan LeFebvre</a> on <a href="https://unsplash.com/photos/gray-nest-thermostat-displaying-at-63-RFAHj4tI37Y?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@peterbeukema?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Peter Beukema</a> on <a href="https://unsplash.com/photos/wind-turbines-on-green-grass-field-under-blue-sky-during-daytime-OovEfuIwBOM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@luolei?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Luo Lei</a> on <a href="https://unsplash.com/photos/white-wind-turbines-on-green-grass-field-under-white-cloudy-sky-during-daytime-Xa4pTwafe5U?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@bfigas?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Bruno Figueiredo</a> on <a href="https://unsplash.com/photos/white-windmill-during-day-KAXSflHqAl0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@nhippert?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Nicolas HIPPERT</a> on <a href="https://unsplash.com/photos/city-skyline-with-lights-turned-on-during-night-time-C82jAEQkfE0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@llehotsky?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Lukáš Lehotský</a> on <a href="https://unsplash.com/photos/gray-asphalt-road-between-green-grass-field-under-blue-sky-during-daytime-Xhle0cfnKrg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                <li>Photo by <a href="https://unsplash.com/@llehotsky?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Lukáš Lehotský</a> on <a href="https://unsplash.com/photos/white-concrete-building-under-white-clouds-during-daytime-ixcHGhae2mg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>

            </ul>
            <h2>Research information</h2>
            <ul>
                <li><a href="https://www.energy.gov/energysaver/small-wind-electric-systems">https://www.energy.gov/energysaver/small-wind-electric-systems</a></li>
                <li><a href="https://www.energy.gov/energysaver/fall-and-winter-energy-saving-tips">https://www.energy.gov/energysaver/fall-and-winter-energy-saving-tips</a></li>
                <li><a href="https://education.nationalgeographic.org/resource/putting-wind-work/">https://education.nationalgeographic.org/resource/putting-wind-work/</a></li>
                <li><a href="https://www.energy.gov/eere/wind/advantages-and-challenges-wind-energy">https://www.energy.gov/eere/wind/advantages-and-challenges-wind-energy </a></li>
                <li><a href="https://www.energy.gov/energysaver/benefits-residential-solar-electricity">https://www.energy.gov/energysaver/benefits-residential-solar-electricity</a></li>
                <li><a href="https://education.nationalgeographic.org/resource/geothermal-energy/">https://education.nationalgeographic.org/resource/geothermal-energy/</a></li>
                <li><a href="https://www.energy.gov/eere/wind/advantages-and-challenges-wind-energy">https://www.energy.gov/eere/wind/advantages-and-challenges-wind-energy</a></li>
                <li><a href="https://www.energy.gov/eere/wind/advantages-and-challenges-wind-energy">https://www.energy.gov/eere/wind/advantages-and-challenges-wind-energy</a></li>
                <li><a href="https://www.energy.gov/ne/articles/advantages-and-challenges-nuclear-energy">https://www.energy.gov/ne/articles/advantages-and-challenges-nuclear-energy</a></li>
            </ul>
            <h2>Work Log</h2>
            <a href="/work-log.pdf">Here</a>
            <h2>Copyright Checklist</h2>
            <a href="/signed-copyright-checklist-web1.pdf">Here</a>
        </div>
    )
}