import Mountain from "../images/landing/FILLER_IMAGE_HEATHER_LAKE.jpg";
import { TextImage } from "../sections/general";
import NewsSection from "../sections/news";
import { stories } from "../dataFiles/news";
import "../css/news.css";

export default function News() {
    return (
        <div className="App">
            <TextImage name="GHP" src={Mountain}>News</TextImage>
            <NewsSection>{stories}</NewsSection>
        </div>
    ) 
}